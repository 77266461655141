<template>
  <div>
    <v-row>
      <v-col cols="6" md="3" class="py-0">
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.start_date"
              label="מתאריך"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.start_date"
            @input="(startDateMenu = false), $emit('updateReport')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="6" md="3" class="py-0">
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.end_date"
              label=" עד תאריך "
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filters.end_date"
            @input="(endDateMenu = false), $emit('updateReport')"
            no-title
            scrollable
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Filters",
  props: ["filters"],
  data() {
    return {
      startDateMenu: false,
      endDateMenu: false,
    };
  },
  computed: {

  },
  methods: {

  },
};
</script>
